import { Link, useStaticQuery, graphql } from "gatsby";
import React, { useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Layout from "../components/layout";
import Button from "react-bootstrap/Button";
import PhotoLeft from "../components/photo-left";
import DenplanCardPage from "../components/denplan-card";
import { GatsbyImage } from "gatsby-plugin-image";
import { BgImage } from "gbimage-bridge";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { Helmet } from "react-helmet";
import { checkPropertiesForNull } from "../../utils";
import { SafeHtmlParser } from "../components/safeHtmlParser";
import uuid4 from "uuid4";

const PriceGuidePage = () => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          siteUrl
        }
      }
      pageData: wpPage(slug: { eq: "price-guide" }) {
        seoFieldGroups {
          metaTitle
          metaDescription
          localBusinessSchema
          openGraphDescription
          openGraphTitle
          productSchema
          image {
            node {
              altText
              publicUrl
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    formats: [AUTO, WEBP]
                    quality: 100
                    transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                    layout: CONSTRAINED
                    placeholder: BLURRED
                  )
                  original {
                    width
                    height
                  }
                }
              }
            }
          }
        }
        priceGuideFieldGroups {
          pricingIntroSection {
            heading
            subHeading
            description
            pricingOptions {
              heading
              description
              cta {
                title
                target
                url
              }
              image {
                node {
                  altText
                  localFile {
                    childImageSharp {
                      gatsbyImageData(
                        formats: [AUTO, WEBP]
                        quality: 100
                        transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                        layout: CONSTRAINED
                        placeholder: BLURRED
                      )
                      original {
                        width
                        height
                      }
                    }
                  }
                }
              }
              plans {
                heading
                plan {
                  heading
                  price
                  isCategory
                }
              }
            }
          }
          pricingTwoColumnSection {
            heading
            content
            image {
              node {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      formats: [AUTO, WEBP]
                      quality: 100
                      transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                      layout: CONSTRAINED
                      placeholder: BLURRED
                    )
                    original {
                      width
                      height
                    }
                  }
                }
              }
            }
          }
          textSectionWithCta {
            heading
            subHeading
            cta {
              target
              title
              url
            }
          }
          pricingAboveFooterImage {
            node {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    formats: [AUTO, WEBP]
                    quality: 100
                    transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                    layout: CONSTRAINED
                    placeholder: BLURRED
                  )
                  original {
                    width
                    height
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  const {
    pageData: { seoFieldGroups, priceGuideFieldGroups },
  } = data;

  const {
    pricingIntroSection,
    pricingTwoColumnSection,
    textSectionWithCta,
    pricingAboveFooterImage,
  } = priceGuideFieldGroups;

  const siteUrl = data.site.siteMetadata.siteUrl;
  const [info, setInfo] = useState("Private");

  const breadcrumb = {
    "@context": "http://schema.org",
    "@type": "BreadcrumbList",
    itemListElement: [
      {
        "@type": "ListItem",
        position: "1",
        name: "Home",
        item: {
          url: `${siteUrl}`,
          id: `${siteUrl}`,
        },

        "@type": "ListItem",
        position: "1",
        name: "Price Guide",
        item: {
          url: `${siteUrl}/price-guide`,
          id: `${siteUrl}/price-guide`,
        },
      },
    ],
  };

  return (
    <>
      <Helmet>
        {" "}
        <script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
      </Helmet>
      {/* <LocalBusinessSchema /> */}
      <GatsbySeo
        title={seoFieldGroups?.metaTitle}
        description={seoFieldGroups?.metaDescription}
        language="en"
        openGraph={{
          type: "website",
          url: `${siteUrl}/price-guide`,
          title: `${seoFieldGroups?.openGraphTitle}`,
          description: `${seoFieldGroups?.openGraphDescription}`,
          images: [
            {
              url: `${seoFieldGroups?.image?.node.localFile.publicURL}`,
              width: `${seoFieldGroups?.image?.node.localFile.childImageSharp.original.width}`,
              height: `${seoFieldGroups?.image?.node.localFile.childImageSharp.original.height}`,
              alt: `${seoFieldGroups?.image?.node.altText}`,
            },
          ],
        }}
      />

      <Layout>
        <section className="pt-md-8 pt-5 ">
          <Container>
            {pricingIntroSection &&
              !checkPropertiesForNull(pricingIntroSection, ["heading"]) && (
                <Row>
                  <Col className="text-center">
                    <p className="text-text-secondary jost-bold fs-6">
                      {pricingIntroSection?.subHeading}
                    </p>
                    <h1 className=" display-4 pb-4">
                      {pricingIntroSection?.heading}
                    </h1>
                    {pricingIntroSection?.description && (
                      <SafeHtmlParser
                        htmlContent={pricingIntroSection?.description}
                      />
                    )}
                  </Col>
                </Row>
              )}
            {pricingIntroSection.pricingOptions &&
              pricingIntroSection.pricingOptions.length > 0 && (
                <Row className="pt-8 h-100 justify-content-center">
                  {pricingIntroSection.pricingOptions.map((item) => (
                    <Col
                      style={{ minHeight: "100%" }}
                      className="mb-6 mb-xl-0"
                      md={6}
                      lg={5}
                      xl={4}
                      key={uuid4()}
                    >
                      <a
                        className="text-decoration-none content-list"
                        href={item.cta?.url}
                        onClick={() => setInfo(item.heading)}
                      >
                        <DenplanCardPage
                          imageHeight="250px"
                          image={
                            item?.image.node?.localFile.childImageSharp
                              .gatsbyImageData
                          }
                          imgAlt={item.image?.node?.altText}
                          list={
                            <SafeHtmlParser htmlContent={item?.description} />
                          }
                          title={item.heading}
                          buttonText={item.cta?.title}
                        />
                      </a>
                    </Col>
                  ))}
                </Row>
              )}
          </Container>
        </section>
        {pricingIntroSection.pricingOptions &&
          pricingIntroSection.pricingOptions.length > 0 && (
            <>
              {pricingIntroSection.pricingOptions.map((pricingItem) => (
                <>
                  {info === pricingItem.heading && (
                    <section
                      id={pricingItem.heading}
                      className="pt-5 pt-lg-7"
                      key={uuid4()}
                    >
                      {pricingItem.plans && pricingItem.plans.length > 0 && (
                        <>
                          {pricingItem.plans.map((planItem) => (
                            <>
                              <Container>
                                <Row className="justify-content-center">
                                  <Col lg={10}>
                                    <h2>{planItem.heading}</h2>
                                  </Col>
                                </Row>
                              </Container>
                              <hr
                                style={{ opacity: 1, height: "3px" }}
                                className="bg-accent"
                              />
                              <Container>
                                {planItem.plan && planItem.plan.length > 0 && (
                                  <Row className="justify-content-center">
                                    <Col lg={10}>
                                      {planItem.plan.map((planItemData) => (
                                        <Row
                                          className="align-items-center"
                                          key={uuid4()}
                                        >
                                          <Col xs={true}>
                                            {planItemData.isCategory ===
                                            "Yes" ? (
                                              <>
                                                
                                                <h3 className="jost-bold mb-4 fs-5 text-third">
                                                  {planItemData?.heading}
                                                </h3>
                                              </>
                                            ) : (
                                              <p className="jost-medium">
                                                {planItemData?.heading}
                                              </p>
                                            )}
                                          </Col>
                                          {planItemData?.price && (
                                            <Col xs={true} className="text-end">
                                              <p className="jost-bold">
                                                {planItemData?.price}
                                              </p>
                                            </Col>
                                          )}
                                        </Row>
                                      ))}
                                    </Col>
                                  </Row>
                                )}
                              </Container>
                              <br />
                              <br />
                            </>
                          ))}
                        </>
                      )}
                    </section>
                  )}
                </>
              ))}
            </>
          )}

        {pricingTwoColumnSection &&
          !checkPropertiesForNull(pricingTwoColumnSection, ["heading"]) && (
            <section className="py-8">
              {" "}
              <PhotoLeft
                img={
                  pricingTwoColumnSection?.image.node?.localFile.childImageSharp
                    .gatsbyImageData
                }
                imgAlt={pricingTwoColumnSection?.image.node?.altText}
                text={
                  <div>
                    <h2 className=" display-4 pb-4">
                      {pricingTwoColumnSection?.heading}
                    </h2>
                    <GatsbyImage
                      className="mb-4 w-100 d-lg-none"
                      image={
                        pricingTwoColumnSection?.image.node?.localFile
                          .childImageSharp.gatsbyImageData
                      }
                      alt={pricingTwoColumnSection?.image.node?.altText}
                    />
                    <SafeHtmlParser
                      htmlContent={pricingTwoColumnSection?.content}
                    />
                  </div>
                }
                bgColour="bg-secondary"
              />
            </section>
          )}
        <section>
          {textSectionWithCta &&
            !checkPropertiesForNull(textSectionWithCta, ["heading"]) && (
              <Container>
                {" "}
                <Row>
                  <Col className="text-center mb-10">
                    <p className="text-text-secondary jost-bold fs-6">
                      {textSectionWithCta?.subHeading}
                    </p>
                    <h2 className=" display-4 pb-4">
                      {textSectionWithCta?.heading}
                    </h2>
                    {textSectionWithCta?.cta && textSectionWithCta?.cta.url && (
                      <Button
                        className="text-white py-2 px-5 w-100 w-md-auto"
                        variant="accent"
                        as={Link}
                        to={textSectionWithCta?.cta.url}
                      >
                        {textSectionWithCta?.cta.title}
                      </Button>
                    )}
                  </Col>
                </Row>
              </Container>
            )}
          {pricingAboveFooterImage && (
            <BgImage
              style={{ height: "60vh", backgroundPosition: "top" }}
              className="w-100 "
              image={
                pricingAboveFooterImage?.node?.localFile.childImageSharp
                  .gatsbyImageData
              }
              alt={pricingAboveFooterImage?.node?.altText}
            />
          )}
        </section>
      </Layout>
    </>
  );
};

export default PriceGuidePage;
