import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import { FaLongArrowAltRight } from "react-icons/fa";

const DenplanCardPage = ({
  image,
  title,
  imgAlt,
  shorter,
  list,
  buttonText
}) => {
  return (
    <div
      style={{
        boxShadow: "0px 3px 50px #00000029",
        background: "none",
        overflow: "hidden",
      }}
      className={` w-100 h-100 feature-box  pb-5 position-relative`}
    >
      <div className={`position-relative denplan-image ${shorter} `}>
        <div
          style={{ zIndex: 0, overflow: "hidden" }}
          className={` position-absolute treatment-card-image-container top-0 `}
        >
          <GatsbyImage
            className="treatment-card-image"
            image={image}
            alt={imgAlt}
          />
        </div>
      </div>
      <div
        style={{ width: "calc(100% + 3px)", left: "-1px", zIndex: 2 }}
        className="bg-white  pt-7 position-relative  card-div"
      >
        <h3 className="fs-4 pb-4 pb-lg-3 text-center  text-text-secondary">
          {title}
        </h3>
        <div
          style={{ height: "96px" }}
          className="d-flex align-items-center ps-5 pe-3"
        >
          {list}
        </div>
        <a className="fs-5 d-flex align-items-center mt-3 pointer justify-content-center gold-link">
          {buttonText || "FIND OUT MORE"}
          <FaLongArrowAltRight className="ms-2 fs-6" />
        </a>
      </div>
    </div>
  );
};

export default DenplanCardPage;
